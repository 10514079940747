<script>
import Layout from "@/views/layouts/main.vue";
import PageHeader from "@/components/page_header_new.vue";
import appConfig from "@/app.config.json";
import {postMutations, postStore} from "@/state/modules/postStoreHelper";
import axios from "axios";
import {isEmpty} from "lodash";
import {loggedInfoObject} from "@/state/helpers";

/**
 * Invoice detail component
 */
export default {
  page: {
    title: "Q&A 상세",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  components: {
    Layout,
    PageHeader,
  },

  data() {
    return {
      title: "Q&A 상세",
      commentContent: "",
      commentResult: {}
    };
  },

  computed: {
    ...postStore,
    ...loggedInfoObject
  },

  mounted() {
    console.log("Im postDetail :", this.postDetail)
    this.getComment()
  },

  methods: {
    isEmpty,
    ...postMutations,

    returnPhoneHyphen(val) {
      return typeof val !== 'undefined' ? val.replace(/[^0-9]/g, '').replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`) : ""
    },

    removeContentTag(val) {
      return typeof val !== 'undefined' ? val.replaceAll(/<[^>]*>?/g, ' ') : ""
    },

    insertComment() {
      let url = `/api/comment/insert`

      let data = {
        document_srl: Number(this.postDetail.document_srl),
        // member_srl: userInfoData.memberSrl,
        nick_name: '관리자',
        content: this.commentContent,
      }

      axios.post(url, data)
          .then((res) => {
            console.log(res)
          })
          .catch((err) => {
            console.log(err)
          })
    },

    getComment() {
      let url = `/api/comment/getListAllCommentsByFilter/1/1`

      let data = {
        document_srl: Number(this.postDetail.document_srl),
      }

      axios.post(url, data)
          .then((res) => {
            console.log(res.data.comment_list[0])
            this.commentResult = res.data.comment_list[0]
          })
          .catch((err) => {
            console.log(err)
          })
    },

    deleteQuestion() {
      let url = `/api/post/delete/${this.postDetail.document_srl}`

      axios.delete(url)
          .then((res) => {
            console.log(res)
          })
          .catch((err) => {
            console.log("deleteQuestion Error :", err)
          })
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title"/>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="invoice-title">
              <div class="mb-2 d-flex justify-content-between align-items-center">
                <h4> {{ postDetail.title }}</h4>
                <b-button v-if="logged_info.level !== 10" class="btn btn-soft-danger" size="sm" style="min-width: 80px">
                  삭제
                </b-button>
              </div>
              <div class="text-muted">
                <p class="mb-1">{{ postDetail.nick_name }} | {{ postDetail.regdate }}</p>
                <p><i class="uil uil-phone me-1"></i>{{ returnPhoneHyphen(postDetail.phone) }}</p>
              </div>
            </div>

            <hr class="my-3"/>

            <div class="row">
              <div class="col-sm-12 font-size-16 mt-2 mb-2" v-purify-html="postDetail.content">
<!--                {{ removeContentTag(postDetail.content) }}-->
              </div>
              <!-- end col -->
            </div>
            <!-- end row -->
            <hr class="my-3"/>

            <div class="mt-4 font-size-16 w-100">
              <div v-if="isEmpty(commentResult) && logged_info.level === 10" class="w-100 d-flex">
                <div class="form-floating w-100 me-3">
                  <textarea
                      class="form-control font-size-16"
                      placeholder="Leave a comment here"
                      id="floatingTextarea2"
                      style="height: 100px"
                      v-model="commentContent"
                  ></textarea>
                  <label for="floatingTextarea2">답변 작성</label>
                </div>
                <b-button class="btn btn-soft-primary" style="min-width: 80px" @click="insertComment">등록</b-button>
              </div>
              <div v-if="!isEmpty(commentResult)"> <!--이 태그는 답변을 이미 작성했을때-->
                <div class="d-flex justify-content-between align-items-center mb-2">
                  <div class="text-muted mb-1">관리자 | {{ commentResult.regdate }}</div>
                  <b-button v-if="logged_info.level === 10" class="btn btn-soft-danger" size="sm"
                            style="min-width: 80px">삭제
                  </b-button>
                </div>
                <div class="font-size-16">
                  {{ commentResult.content }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
